// 引入国际化
import i18n from '@/lang'

export default {
  data: [
    { id: '1', title: i18n.t('header.home'), route: '/' },
    { id: '2', title: i18n.t('header.product'), route: '/products/1' },
    { id: '3', title: i18n.t('header.solution'), route: '/solutions' },
    { id: '4', title: i18n.t('header.job'), route: '/jobs' },
    { id: '5', title: i18n.t('header.about'), route: '/about' }
  ]
}
