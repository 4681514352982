export default {
  data: [
    {
      id: '1',
      title: '磁光冷热分级数据中心节碳降电解决方案',
      titleEn: 'Carbon saving and power reduction',
      content:
        '该系统综合了磁和光两种物理存储介质的优势，融合磁存储应用系统与蓝光数据存储控制系统，利用存储虚拟化技术、分布式文件系统、智能分层存储管理系统等软件技术，对各类软硬件进行综合设计，通过具有高密度、高安全、长期保存、绿色节能的混合存储基础架构、存储管理软件、分层存储算法，按生命周期对数据进行管理，实现对热、温、冷数据的智能分层存储管理。',
      cover: require('@/assets/solution/solution-cover-1.jpeg'),
      image: require('@/assets/solution/solution-image-1.jpeg'),
      cases: ['1']
    },
    {
      id: '2',
      title: '磁光组合增容节能老旧数据中心改造解决方案',
      titleEn: 'Old data center transformation',
      content:
        '该方案用蓝光存储作为底层冷存储介质，来降低很少访问甚至永远不会被访问的冷数据存储高成本问题。数据量的飞速增长，并且由“热”变“冷”的现象日益凸显，按照“二八定律”，经过一段时间的使用，80%的数据都会变为冷数据。冷数据不需要高性能的存储设备，根据需要采用近线或离线存储设备将数据以最安全、最节能、最环保的方式，在数据永久安全存储的前下可确保安全稳定，并可随时调阅、回迁，为系统提供永久有效的安全、准确的数据服务。',
      cover: require('@/assets/solution/solution-cover-2.jpeg'),
      image: require('@/assets/solution/solution-image-2.jpeg'),
      cases: ['2']
    },
    {
      id: '3',
      title: '影像档案存储解决方案',
      titleEn: 'Image file storage',
      content:
        '通过光存储管理系统提供基于档案级BD光盘和光盘库、离线库的数据存储服务，实现面向各类需归档数据的采集，数据刻录存储、数据近线及离线管理、数据查询与恢复的“全生命周期”功能，实现对所有影像档案数据的统一集中存储管理。系统为企业提供高度安全性档案存储解决方案。其主要特点：自动安排和执行影像档案数据存储、自动管理及优化存储空间、增强的数据和操作管理、自动化影像档案数据光存储查询。该方案支持PB级别海量数据的存储与管理，离线管理能力使其能够完全适应海量数据无限积累存储，实现数据增长不会对方案主体设备结构构成影响，在数据存储规模上具备良好的兼容性、可扩展性。',
      cover: require('@/assets/solution/solution-cover-3.jpeg'),
      image: require('@/assets/solution/solution-image-3.png')
    },
    {
      id: '4',
      title: '交通数据高可靠存储解决方案',
      titleEn: 'Highly reliable storage of traffic data',
      content:
        '通过交通信息数据中心对于违章、闯红灯、超速、城市事故、流量，结合数据中心数据库系统等涵盖交通海量数据分类存储，为相关大数据检索、分析等新业务系统提供支撑。磁光存储除了支撑海量非结构化数据的存储和调阅外，基于全分布式的数据和元数据存储架构，集成智能数据预处理、索引库、分类分级等相关模块，为用户提供永久的数据在线存储、备份、近线归档、离线灾备等多种建设与数据存储需求的定制化、系统级存储平台服务。',
      cover: require('@/assets/solution/solution-cover-4.jpeg'),
      image: require('@/assets/solution/solution-image-4.png')
    },
    {
      id: '5',
      title: '公共资源交易中心全自动磁光备份存储解决方案',
      titleEn: 'automatic magneto-optical backup',
      content:
        '磁光自动刻录打印备份系统是针对公共资源交易中心行业需求而量身定制。解放人工手动查询导出视频到本地，手动换取光盘刻录模式，系统实现自动按项目下载开/评标视频数据并自动刻录和光盘封面信息打印。集成行业定制软件、采用高性能机械手、标准通用光驱、服务器，全面支持CD/DVD/BD数据光盘。系统拥有友好的中文软件操作界面，为用户提供更加便捷的操作从而大大提高工作效率。',
      cover: require('@/assets/solution/solution-cover-5.jpeg'),
      image: require('@/assets/solution/solution-image-5.jpg')
    },
    {
      id: '6',
      title: '保密信息安全传输解决方案',
      titleEn: 'Secure transmission',
      content:
        '双网物理隔离信息安全传输审计系统是一套符合第五代隔离技术-完全物理隔离“安全通道传输系统”。通过专用通信设备、专有安全协议、加密验证机制以及应用层数据提取和鉴别认证技术系统，可进行不同安全级别网络之间的数据传输，同时对网间通信的双方、内容、过程施以严格的身份认证、内容过滤、安全审计的安全防护机制，杜绝了由于操作系统和网络协议自身漏洞带来的安全风险。物理隔离单向传输技术、三权分立认证机制完全符合国家保密信息传输以及“等保2.0”技术要求。',
      cover: require('@/assets/solution/solution-cover-6.jpeg'),
      image: require('@/assets/solution/solution-image-6.jpg'),
      cases: ['5']
    }
  ]
}
