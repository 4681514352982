<template>
  <div class="footer-content">
    <div class="footer-footer">
      <div class="footer-items">
        <!-- <el-image class="item-qrcode" :src="require('@/assets/common/qrcode.jpg')" /> -->
        <div>
          <div class="footer-item-title" @click="productListClickHandler()">{{ $t('header.product') }}</div>
          <div class="footer-item-line"></div>
          <div class="footer-item-content">
            <div v-for="item in productList" :key="item.id" @click="productClickHandler(item)">
              {{ item.title }}
            </div>
          </div>
        </div>
        <div class="footer-item">
          <div class="footer-item-title" @click="solutionListClickHandler()">{{ $t('header.solution') }}</div>
          <div class="footer-item-line"></div>
          <div class="footer-item-content">
            <div v-for="item in solutionList" :key="item.id" @click="solutionClickHandler(item)">
              {{ item.title }}
            </div>
          </div>
        </div>

        <div class="footer-item">
          <div class="footer-item-title" @click="aboutClickHandler()">{{ $t('header.about') }}</div>
          <div class="footer-item-line"></div>
          <div class="footer-item-content">
            <div>{{ $t('info.email') }}{{ info.email }}</div>
            <div class="footer-item-address">
              {{ $t('info.address') }}
              <span class="footer-content-address">{{ info.address }}</span>
            </div>
          </div>
        </div>

        <div class="footer-item">
          <div class="footer-item-title">{{ $t('footer.partner') }}</div>
          <div class="footer-item-line"></div>
          <div class="footer-item-content">
            <div style="display: flex; flex-direction: column">
              <div v-for="item in partnerList" :key="item.id" class="footer-partners">
                {{ item.title }}
              </div>
            </div>
          </div>
        </div>
      </div>
      <div class="footer-line"></div>
      <div class="footer-copyright">{{ $t('footer.copyright') }}</div>
      <div class="footer-icp">
        <a href="http://beian.miit.gov.cn/" target="_blank">{{ $t('footer.icp') }}</a>
        <a href="https://www.beian.gov.cn/portal/registerSystemInfo?recordcode=11011302005703" target="_blank">
          <img
            style="width: 15px; height: 15px; vertical-align: -2px; margin-left: 10px; margin-right: -2px"
            src="@/assets/common/beian.jpg"
            alt=""
          />
          {{ $t('footer.beian') }}
        </a>
      </div>
    </div>
  </div>
</template>
<script>
import products from '@/data/product-list.js'
import solutions from '@/data/solution-list.js'
import partners from '@/data/partner-list'
import info from '@/data/info.js'

/**
 * 网站底部视图
 */
export default {
  name: 'FooterView',
  props: {},
  data() {
    return {
      /** 产品数据列表 */
      productList: products.data,
      /** 解决方案数据列表 */
      solutionList: solutions.data,
      /** 公司信息 */
      info: info,
      /** 合作伙伴数据列表 */
      partnerList: partners.data
    }
  },
  methods: {
    /**
     * 产品点击触发
     * @param {Object} item 产品对象
     */
    productClickHandler(item) {
      this.$router.push('/product-detail/' + item.id)
    },
    /**
     * 解决方案点击触发
     * @param {Object} item 解决方案对象
     */
    solutionClickHandler(item) {
      this.$router.push('/solution-detail/' + item.id)
    },
    /**
     * 产品列表点击触发
     */
    productListClickHandler() {
      this.$router.push('/products')
    },
    /**
     * 解决方案列表点击触发
     */
    solutionListClickHandler() {
      this.$router.push('/solutions')
    },
    /**
     * 关于我们点击触发
     */
    aboutClickHandler() {
      this.$router.push('/about')
    }
  }
}
</script>
<style lang="scss" scoped>
.footer-content {
  width: 100%;
  padding: 30px 0px 20px;

  background-image: url(@/assets/footer/footer-background.png);

  display: flex;
  flex-direction: row;
  justify-content: center;
}
.footer-content .footer-footer {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.footer-content .footer-footer .footer-items {
  display: flex;
  justify-content: center;
}

.footer-content .footer-footer .footer-items .item-qrcode {
  width: 140px;
  height: 140px;
  margin-top: 7px;
}

.footer-content .footer-footer .footer-items .footer-item {
  margin-left: 30px;
}

.footer-content .footer-footer .footer-line {
  width: 800px;
  height: 2px;
  background: linear-gradient(46deg, rgba(255, 255, 255, 0) 0%, #ffffff 53%, rgba(255, 255, 255, 0) 100%);
  opacity: 1;
  border-radius: 0px;
  margin-top: 30px;
}

.footer-content .footer-footer .footer-copyright {
  display: flex;
  justify-content: center;
  margin-top: 10px;
  width: 100%;
  height: 25px;
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  line-height: 32px;
  color: #ffffff;
}

.footer-content .footer-footer .footer-icp {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 25px;
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  line-height: 32px;
  color: #ffffff;
}

.footer-content .footer-footer .footer-icp {
  display: flex;
  justify-content: center;
  width: 100%;
  height: 25px;
  font-size: 12px;
  font-family: Alibaba PuHuiTi;
  font-weight: 400;
  line-height: 32px;
  color: #ffffff;
}

.footer-content-title {
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-weight: 400;
  color: #ffffff;
  margin-top: 5px;
  cursor: pointer;
}

.footer-item-title {
  height: 20px;
  font-size: 14px;
  font-weight: 500;
  line-height: 32px;
  color: #ffffff;
  cursor: pointer;
}

.footer-item-line {
  width: 27px;
  height: 3px;
  background: #ffffff;
  margin-top: 16px;
  border-radius: 2px;
}

.footer-item-content {
  margin-top: 10px;
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
  color: #ffffff;
  display: flex;
  flex-direction: column;
  gap: 5px;
  cursor: pointer;
}

.footer-item-address {
  display: flex;
}

// .footer-item-email {
// margin-top: 10px;
// }

.footer-item-partner {
  // margin-top: 4px;
}

.footer-content-address {
  width: 200px;
  font-family: Source Han Sans CN;
  font-size: 12px;
  font-weight: 400;
  line-height: 18px;
}

// .first-item {
//   margin-left: 80px;
// }

.footer-partners {
  margin-top: 5px;

  &:first-child {
    margin-top: 0px;
  }
}
</style>
