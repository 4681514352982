<template>
  <div class="header">
    <img class="header-logo" src="@/assets/common/LOGO-H.png" alt="" />
    <el-menu :default-active="menuActiveIndex" mode="horizontal" text-color="#333333" active-text-color="#548135" router>
      <el-menu-item v-for="(item, i) in menuList" :key="item.id" class="header-menu-item" :index="i + 1 + ''" :route="item.route">
        {{ item.title }}
      </el-menu-item>
    </el-menu>
    <div class="header-search"></div>
  </div>
</template>
<script>
import menuList from '@/data/menu.js'
/**
 * 网站头部视图
 * @param {Number} index 菜单选中索引
 */
export default {
  name: 'HeaderView',
  props: {
    index: {
      type: Number,
      default: 0
    }
  },
  data() {
    return {
      /** 菜单数据列表 */
      menuList: menuList.data
    }
  },
  computed: {
    /**
     * 菜单选中索引
     * @return {String} 菜单选中索引
     */
    menuActiveIndex() {
      const index = this.index + 1 + ''
      return index
    }
  },
  methods: {}
}
</script>
<style lang="scss" scoped>
.header {
  width: 100%;
  height: 70px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  padding: 0px 100px;

  background-color: white;
}

.header .header-logo {
  width: 140px;
  height: 32px;
}

.header .header-menu-item {
  margin-left: 20px;
  font-family: Source Han Sans CN;
  font-weight: 400;
  color: #333333;
  font-size: 14px;

  &:first-child {
    margin-left: 40px;
  }
}

.header .header-search {
  width: 140px;
  height: 32px;
}

// 去除Menu底部下划线
.el-menu.el-menu--horizontal {
  border-bottom: none;
}
</style>
