export default {
  fixPlatform() {
    if (process.env.NODE_ENV === 'development') {
      return
    }
    // 平台、设备和操作系统
    var system = {
      win: false,
      mac: false,
      xll: false,
      ipad: false
    }
    // 检测平台
    var p = navigator.platform
    system.win = p.indexOf('Win') === 0
    system.mac = p.indexOf('Mac') === 0
    system.x11 = p === 'X11' || p.indexOf('Linux') === 0
    if (!system.win && !system.mac && !system.xll) {
      // 如果是手机访问，就跳转到H5网址
      if (window.location.href.indexOf('www.huixinsky')) {
        window.location.href = 'https://' + window.location.host.replace('www.', 'm.')
      }
    }
  }
}
