import Vue from 'vue'

import 'amfe-flexible/index.js'

import 'normalize.css/normalize.css'
// 引入element-ui样式表
import Element from 'element-ui'
import './styles/element-variables.scss'
// 加载全局样式
import '@/styles/index.scss'

import App from './App'
import store from './store'
import router from './router'
// 引入国际化
import i18n from './lang'
// 引入图标
import './icons'

import bootUp from '@/js/boot-up.js'
// 识别平台
bootUp.fixPlatform()

// 设置默认的element-ui的尺寸
Vue.use(Element, {
  size: localStorage.getItem('size') || 'medium',
  i18n: (key, value) => i18n.t(key, value)
})

// 如果是开发环境则打开提示
if (process.env.NODE_ENV === 'development') {
  Vue.config.productionTip = true
} else {
  Vue.config.productionTip = false
}

new Vue({
  el: '#app',
  router,
  store,
  i18n,
  render: (h) => h(App)
})
