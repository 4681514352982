<template>
  <div id="app" class="app">
    <HeaderView :index="currentMenuIndex" class="fit-screen" />
    <!-- <keep-alive>
      <router-view v-if="$route.meta.keepAlive" :class="showFooter ? '' : 'hide-footer'" />
    </keep-alive> -->
    <router-view :class="['fit-screen', showFooter ? '' : 'hide-footer']" />
    <FooterView v-if="showFooter" class="fit-screen" />
  </div>
</template>

<script>
import HeaderView from '@/components/header/index.vue'
import FooterView from '@/components/footer/index.vue'

export default {
  name: 'App',
  components: {
    HeaderView,
    FooterView
  },
  data() {
    return {
      currentMenuIndex: 0,
      showFooter: true
    }
  },
  watch: {
    $route(to, from) {
      this.currentMenuIndex = to.meta.menuIndex
      this.showFooter = to.meta.menuIndex !== undefined
    }
  }
}
</script>

<style lang="scss" scoped>
.hide-footer {
  height: calc(100% - 100px);
}

.app {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: rgb(243, 243, 243);
}

@media (min-width: 1200px) {
  .fit-screen {
    width: 1200px;
    background-color: white;
  }
}
</style>
