import Vue from 'vue'
import Router from 'vue-router'

Vue.use(Router)

const routes = [
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/home/index'),
    meta: {
      menuIndex: 0,
      keepAlive: true
    }
  },
  {
    path: '/products/:id',
    name: 'product',
    component: () => import('@/views/product-list/index'),
    meta: {
      menuIndex: 1,
      keepAlive: true
    }
  },
  {
    path: '/solutions',
    name: 'solution',
    component: () => import('@/views/solution-list/index'),
    meta: {
      menuIndex: 2,
      keepAlive: true
    }
  },
  {
    path: '/jobs',
    name: 'job',
    component: () => import('@/views/job-list/index'),
    meta: {
      menuIndex: 3,
      keepAlive: true
    }
  },
  {
    path: '/about',
    name: 'about',
    component: () => import('@/views/about/index'),
    meta: {
      menuIndex: 4,
      keepAlive: true
    }
  },
  {
    path: '/licenses',
    name: 'license',
    component: () => import('@/views/license-list/index'),
    meta: {
      menuIndex: 4,
      keepAlive: true
    }
  },
  {
    path: '/product-detail/:id',
    name: 'productDetail',
    component: () => import('@/views/product-detail/index'),
    meta: {
      menuIndex: 1
    }
  },
  {
    path: '/solution-detail/:id',
    name: 'solutionDetail',
    component: () => import('@/views/solution-detail/index'),
    meta: {
      menuIndex: 2
    }
  },
  {
    path: '/product-case-detail/:id',
    name: 'productCaseDetail',
    component: () => import('@/views/case-detail/index'),
    meta: {
      menuIndex: 1
    }
  },
  {
    path: '/solution-case-detail/:id',
    name: 'solutionCaseDetail',
    component: () => import('@/views/case-detail/index'),
    meta: {
      menuIndex: 2
    }
  },
  {
    path: '/job-detail/:id',
    name: 'jobDetail',
    component: () => import('@/views/job-detail/index'),
    meta: {
      menuIndex: 3
    }
  },
  {
    path: '/dynamic-detail/:id',
    name: 'dynamicDetail',
    component: () => import('@/views/dynamic-detail/index'),
    meta: {
      menuIndex: 0
    }
  },
  {
    path: '/404',
    component: () => import('@/views/error-page/index'),
    meta: {
      menuIndex: 0,
      keepAlive: true
    }
  },
  // 404 page must be placed at the end !!!
  {
    path: '*',
    redirect: '/404'
  }
]

const createRouter = () =>
  new Router({
    mode: 'history', // require service support
    scrollBehavior(to, from, savedPosition) {
      if (savedPosition) {
        return savedPosition
      } else {
        return {
          x: 0,
          y: 0
        }
      }
    },
    routes: routes
  })

const originalPush = Router.prototype.push
Router.prototype.push = function push(location, onResolve, onReject) {
  if (onResolve || onReject) {
    return originalPush.call(this, location, onResolve, onReject)
  }
  return originalPush.call(this, location).catch((err) => err)
}

const router = createRouter()

// Detail see: https://github.com/vuejs/vue-router/issues/1234#issuecomment-357941465
export function resetRouter() {
  const newRouter = createRouter()
  router.matcher = newRouter.matcher // reset router
}

export default router
